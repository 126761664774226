import React, { memo } from "react";

// Libraries
import { Col, Container, Row } from "react-bootstrap";
import { PropTypes } from "prop-types";

// Components
import FooterMenu, { Footer } from "./Footer";

// Data
import FooterData from "./FooterData";

import teckatLogo from "../../../Assets/img/BhartiEdu/logo/teckat-logo.png";
import logo from "../../../Assets/img/BhartiEdu/logo/bhartieduWhite.webp";
import SocialIcons from "../SocialIcon/SocialIcons";

const iconData = [
  {
    color: "#828282",
    link: "https://www.facebook.com/bhartied/",
    icon: "fab fa-facebook-f",
  },
  // {
  //   color: "#828282",
  //   link: "https://dribbble.com/",
  //   icon: "fab fa-dribbble",
  // },
  // {
  //   color: "#828282",
  //   link: "https://twitter.com/",
  //   icon: "fab fa-twitter",
  // },
  {
    color: "#828282",
    link: "https://www.instagram.com/bhartieducationconsultancy_/",
    icon: "fab fa-instagram",
  },
];

const FooterStyle03 = (props) => {
  return (
    <Footer
      theme={props.theme}
      className={`${
        props.className ? ` ${props.className}` : ""
      } footer-style-03`}
    >
      <div className="py-[6%] lg:py-[8%] md:py-[50px] md:pb-0 xs:py-[50px]">
        <Container>
          <Row className="justify-between md:justify-start">
            <Col
              lg={{ span: 3, order: 0 }}
              sm={{ span: 6, order: 1 }}
              className="md:mb-[40px] xs:mb-[25px]"
            >
              <span className="font-serif font-medium block text-themecolor mb-[20px] xs:mb-[10px]">
                <img
                  className="default-logo"
                  width="150"
                  height="150"
                  loading="lazy"
                  src={logo}
                  data-rjs={logo}
                  alt="logo"
                />
              </span>
              <p className="md:w-9/12 xs:w-full">
                Our team of experienced counselors guides students through the
                entire direct admissions process, from selecting suitable
                colleges and submitting applications.
              </p>
              <div className="mt-4">
                <SocialIcons
                  theme="social-icon-style-01"
                  className="justify-start"
                  size="xs"
                  iconColor={props.theme === "dark" ? "light" : "dark"}
                  data={iconData}
                />
              </div>
            </Col>
            <FooterMenu
              data={FooterData.slice(0, 2)}
              lg={{ span: 2, order: 0 }}
              md={6}
              sm={{ span: 6, order: 2 }}
              className="xl:px-[15px] md:mb-[40px] xs:mb-[25px]"
              titleClass="capitalize leading-[20px] mb-[25px]"
            />
            <Col
              xl={{ span: 3 }}
              lg={{ span: 4, order: 0 }}
              sm={{ span: 6, order: 3 }}
              md={5}
            >
              <span className="font-serif font-medium block text-themecolor mb-[20px] xs:mb-[10px]">
                Address
              </span>
              <div className="mb-[25px] md:mb-[20px]">
                <table>
                  <tbody>
                    <tr>
                      <th className="flex items-start">
                        <i className="line-icon-Geo2-Love text-gradient font-semibold bg-[#fff] text-[22px]  inline-block translate-y-1"></i>
                      </th>
                      <td className="pb-3 pl-2">
                        5th floor 4/2 Sulata Mala Complex, 66 Pennar road,
                        Sakchi Jamshedpur, Jharkhand, India 831001
                      </td>
                    </tr>
                    <tr>
                      <th className="flex items-start">
                        <i className="line-icon-Mail-Read text-gradient font-semibold bg-[#fff] text-[22px]  inline-block translate-y-1"></i>
                      </th>
                      <td className="pb-3 pl-2 ">
                        <a href="mailto:info.bharti2015@gmail.com">
                          info.bharti2015@gmail.com
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <th className="flex items-start">
                        <i className="line-icon-Telephone font-semibold text-gradient bg-[#ffff] text-[25px]  inline-block translate-y-1"></i>
                      </th>
                      <td className="pl-2 ">
                        <a href="tel:+916572442721">+91 6572442721</a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="py-[35px] border-t border-[#ffffff1a]">
        <Container>
          <Row>
            <Col
              xl={{ span: 6 }}
              lg={{ span: 6, order: 0 }}
              sm={{ span: 12, order: 3 }}
              md={12}
            >
              <p>© 2023 Bharati Education | All Rights Reserved.</p>
            </Col>
            <Col
              xl={{ span: 6 }}
              lg={{ span: 6, order: 0 }}
              sm={{ span: 12, order: 3 }}
              md={12}
            >
              <div className="flex items-center justify-end md:justify-start">
                Developed By
                <a
                  aria-label="link"
                  href="https://teckat.com"
                  target="_blank"
                  rel="noreferrer"
                  className=" text-themecolor font-medium hover:text-basecolor flex items-center"
                >
                  <img
                    src={teckatLogo}
                    alt="teckat logo"
                    className="w-[30px] mx-1"
                  />
                  <span className="hover:text-[#f78c1f]">Teckat.com</span>
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </Footer>
  );
};

FooterStyle03.defaultProps = {
  data: FooterData,
  logo: "/assets/img/webp/logo-white.webp",
};

FooterStyle03.propTypes = {
  className: PropTypes.string,
  logo: PropTypes.string,
};

export default memo(FooterStyle03);
