import React, { useEffect, useRef, useState } from "react";

/** Libraries */
import { Col, Container, Row } from "react-bootstrap";
import { m } from "framer-motion";
import { Parallax } from "react-scroll-parallax";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import { Link as ScrollTo } from "react-scroll";

/** Components */
import { fadeIn } from "../../../Functions/GlobalAnimations";
import Buttons from "../../../Components/Button/Buttons";

/** style */
import "../../../../Assets/scss/BhartiEdu/pages/home.scss";
// Image
import contact from "../../../../Assets/img/BhartiEdu/home/contact.webp";
import banner from "../../../../Assets/img/BhartiEdu/course/banner2.webp";

import topUniversityData from "./PharmacyCollege";
import WhatsappButton from "../../../Components/WhatsappButton/WhatsappButton";
/** top university data array */

function Pharmacy() {
  const [formLoading, setFormLoading] = useState(false);
  const collegeSectionRef = useRef(null);
  const location = useLocation();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "all" });

  const onSubmit = (data) => {
    console.log(data);
    setFormLoading(true);
    toast.success("Your message has been sent successfully!", {
      theme: "colored",
    });

    setTimeout(() => {
      setFormLoading(false);
    }, 1000);
  };

  const handleKeyDown = (event) => {
    const allowedKeys = ["Backspace", "Delete", "ArrowLeft", "ArrowRight"];
    const isNumberKey = !isNaN(Number(event.key)) && event.code !== "Space";

    if (!allowedKeys.includes(event.code) && !isNumberKey) {
      event.preventDefault();
    }
  };

  /** scroll to college */

  useEffect(() => {
    if (
      location.search.includes("scrollToCollege=true") &&
      collegeSectionRef.current
    ) {
      collegeSectionRef.current.scrollIntoView({ behavior: "smooth" });
      console.log("hi");
    }
  }, [location.search]);

  return (
    <>
      {/* banner Parallax Scrolling Start */}
      <m.div
        className="md:flex md:items-center overflow-hidden relative md:h-[465px] sm:h-[350px] xs:h-[357px]"
        {...fadeIn}
      >
        <Parallax
          className="lg-no-parallax bg-cover cover-background absolute left-0 h-[120vh] top-[60px] lg:h-[64vh] lg:top-[-46px] lg:!translate-y-0 md:h-[60vh] md:-top-[30px] sm:top-[-44px] sm:h-[49vh] xs:top-0 xs:h-[40vh] w-full"
          translateY={[-40, 40]}
          style={{
            backgroundImage: `url(${banner})`,
          }}
        ></Parallax>
        {/* <div className="absolute h-full w-full opacity-75 top-0 left-0 bg-gradient-to-tr from-[#f5f5f8] via-[#dfdedd] to-[#bfb6b1]"></div> */}
        <Container className="h-full relative">
          <Row className="justify-center h-full">
            <Col
              xl={6}
              lg={7}
              md={10}
              className="text-center flex h-[560px] lg:h-[480px] md:h-[450px] sm:h-[350px] justify-center flex-col font-serif"
            >
              <span className="inline-block opacity-60 mb-[5px] text-[#f78c1f]">
                {/* Physics, Chemistry, Maths */}
              </span>
              <h1 className="text-white text-[42px] leading-[49px] sm:text-[30px] sm:leading-10 font-medium mb-0">
                B Pharma / Pharm D
              </h1>
            </Col>
          </Row>
        </Container>
      </m.div>
      {/* banner Parallax Scrolling End */}

      {/* instruction section start  */}
      <section className="bg-lightgray py-[100px] pb-[100px] lg:py-[50px]  md:py-[80px] md:pb-[120px]  sm:py-[50px]">
        <Container>
          {/* Details */}
          <p>
            The Bachelor of Pharmacy, or B Pharma, is a pharmacy undergraduate
            degree program. The four-year B Pharma program is broken up into
            eight semesters, with two semesters per year. Pharmaceutical science
            courses including drug safety, discovery, medicinal chemistry,
            industrial pharmacy, and many more are studied by bachelor of
            pharmacy students.
          </p>

          <WhatsappButton />
          <div className="mt-[50px] ">
            <div className="text-[#1a1c45] font-semibold text-[30px] md:text-[22px] sm:text-[18px] mb-4">
              Why Opt for B Pharma?
            </div>
            <p>
              Students pursuing a Bachelor of Pharmacy have many options within
              the scope of the program. B Pharma offers information on which
              allopathic medications to take and when. The candidate gains
              expertise regarding the applications of herbal remedies. A
              B.Pharma. degree aids in obtaining a license to administer
              allopathic medications, thereby registering the student as a
              pharmacist.
            </p>
          </div>
          <div className="mt-[50px] ">
            <div className="text-[#1a1c45] font-semibold text-[30px] md:text-[22px] sm:text-[18px] mb-4">
              B Pharmacy Eligibility Criteria
            </div>
            <p>
              B Pharmacy eligibility criteria are a collection of requirements
              that candidates must meet in order to be qualified for a specific
              course. Students who want to enroll in a B Pharmacy course must
              complete the requirements listed below for eligibility:
            </p>
            <div>
              <ol className="!text-[#616162] mt-4 ml-10  md:ml-5 list-decimal">
                <li>
                  The courses of physics, chemistry, maths, and biology are
                  required for students to pass their 10+2.
                </li>
                <li>
                  Students must receive at least 50% of the possible points in
                  the qualifying exam in order to be admitted.
                </li>
                <li>
                  It is also possible for students to pursue a B Pharmacy study
                  after completing their D Pharma course or three-year pharmacy
                  diploma.
                </li>
              </ol>
            </div>
          </div>

          <WhatsappButton />
        </Container>
      </section>
      {/* instruction section start  */}

      {/* Top ranking university section start */}
      <section
        ref={collegeSectionRef}
        className="university_section  py-[50px] pb-[100px] lg:py-[50px]  md:py-[80px] md:pb-[120px]  sm:py-[50px] "
      >
        <Container>
          <div>
            <div className="mt-[30px] lg:mt-[30px] md:mb-16 sm:mb-36 xs:mb-10 md:mt-0">
              <div className=" title  text-center  text-[12px] text-[black]">
                <span>TOP B Pharma/Pharm D COLLEGES IN</span>
                <span className="line_icon px-1">
                  <b>INDIA</b>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 500 150"
                    preserveAspectRatio="none"
                  >
                    <path d="M7.7,145.6C109,125,299.9,116.2,401,121.3c42.1,2.2,87.6,11.8,87.3,25.7"></path>
                  </svg>
                </span>
              </div>
            </div>
            <div className="mt-[50px] lg:mt-[30px]  md:mt-0">
              <div className="university_cards px-5 lg:px-5 md:!px-0 ">
                <Row className="">
                  {topUniversityData.map((item, i) => {
                    return (
                      <Col lg={3} md={6} sm={6} xs={12} key={i}>
                        <ScrollTo
                          to={`course${i}`}
                          offset={-100}
                          delay={0}
                          spy={true}
                          smooth={true}
                          duration={800}
                          className="cursor-pointer w-full"
                        >
                          <div
                            className="block-animation cursor-pointer "
                            style={{ backgroundColor: item.bgColor }}
                          >
                            <div className=" flex justify-center text-white">
                              <div className="">{item.stateName}</div>
                            </div>
                          </div>
                        </ScrollTo>
                      </Col>
                    );
                  })}
                </Row>
              </div>
            </div>
          </div>
        </Container>
      </section>

      <section
        ref={collegeSectionRef}
        className="university_section  py-[50px] pb-[100px] lg:py-[50px]  md:py-[80px] md:pb-[120px]  sm:py-[50px] "
      >
        <Container>
          <div className="mt-[50px] ">
            {topUniversityData.map((item, i) => {
              return (
                <div className="mb-[50px]" key={i} id={`course${i}`}>
                  <div className="text-[#1a1c45] font-semibold text-[30px] md:text-[22px] sm:text-[18px]">
                    {item.title}
                  </div>
                  <div>
                    <ol className="!text-[#616162] mt-4 ml-10  md:ml-5 list-disc flex flex-wrap">
                      {item.colleges.map((college, j) => {
                        return (
                          <li
                            className="w-1/2 xl:w-1/2 lg:w-full md:w-full sm:w-full mb-2"
                            key={j}
                          >
                            {college.name}
                          </li>
                        );
                      })}
                    </ol>
                  </div>
                </div>
              );
            })}
          </div>
        </Container>
      </section>
      {/* Top ranking university section end */}

      {/* enquiry form section start */}
      <m.section
        className="py-[160px] bg-cover 	bg-no-repeat overflow-hidden relative bg-center lg:py-[120px] md:py-[95px] xs:py-[80px] xxs:py-[50px]"
        style={{
          backgroundImage: `url(${contact})`,
        }}
        {...fadeIn}
      >
        <Container className="xs:px-0">
          <div className="flex justify-center mb-[100px] w-full">
            <p className="font-normal text-[20px] w-[70%] lg:w-[70%] md:w-[100%] text-center">
              Get Direct Admission in the Best MBA Colleges of India. Kindly
              submit your data below and we shall connect you as soon as
              possible.
            </p>
          </div>
          <Row className="justify-end md:justify-center">
            <Col xl={7} lg={7} md={9} sm={11}>
              <div className="px-[3rem] py-[5rem] rounded-md shadow-[0_0_30px_rgb(0,0,0,0.08)] bg-white sm:p-20 xs:rounded-none xs:px-[3.5rem] xs:py-[6rem]">
                <span className="mb-[15px] font-medium text-center text-[#f78c1f] text-md font-serif uppercase block sm:mb-[10px]">
                  Submit your details here
                </span>
                <h5 className="w-[80%] mb-[40px] font-bold text-center	tracking-[-1px] text-black font-serif uppercase mx-auto xs:w-full">
                  YOU ARE JUST A CALL AWAY
                </h5>

                <form className="mb-[30px]" onSubmit={handleSubmit(onSubmit)}>
                  <m.div
                    className="row justify-center"
                    {...{ ...fadeIn, transition: { delay: 0.6 } }}
                  >
                    <Col xl={6} lg={6} md={6} sm={12}>
                      <div className="mb-[33px]">
                        <input
                          type="text"
                          name="name"
                          className="rounded-[5px] relative py-[13px] px-[20px]  w-full border-[1px] border-solid border-[#dfdfdf] overflow-hidden"
                          placeholder="Your name"
                          {...register("name", {
                            required: {
                              value: true,
                              message: "Name is required",
                            },
                          })}
                        />
                        <div className="absolute text-[tomato] text-[12px] translate-y-[-3px] translate-x-[10px]">
                          {errors.name?.message}
                        </div>
                      </div>
                    </Col>
                    <Col xl={6} lg={6} md={6} sm={12}>
                      <div className="mb-[33px]">
                        <input
                          type="text"
                          name="email"
                          className="rounded-[5px] py-[13px] px-[20px]  w-full border-[1px] border-solid border-[#dfdfdf] overflow-hidden"
                          placeholder="Your email address"
                          {...register("email", {
                            required: {
                              value: true,
                              message: "Email is required.",
                            },
                            pattern: {
                              value:
                                /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
                              message: "Enter valid email",
                            },
                          })}
                        />
                        <div className="absolute text-[tomato] text-[12px] translate-y-[-3px] translate-x-[10px]">
                          {errors.email?.message}
                        </div>
                      </div>
                    </Col>
                    <Col xl={6} lg={6} md={6} sm={12}>
                      <div className="mb-[33px]">
                        <input
                          type="number"
                          name="phone"
                          className="rounded-[5px] py-[13px] px-[20px]  w-full border-[1px] border-solid border-[#dfdfdf] overflow-hidden"
                          placeholder="Your phone number"
                          onKeyDown={handleKeyDown}
                          {...register("phone", {
                            required: {
                              value: true,
                              message: "Phone number is required",
                            },
                            pattern: {
                              value:
                                /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/,
                              message: "Enter valid phone number",
                            },
                          })}
                        />
                        <div className="absolute text-[tomato] text-[12px] translate-y-[-3px] translate-x-[10px]">
                          {errors.phone?.message}
                        </div>
                      </div>
                    </Col>
                    <Col xl={6} lg={6} md={6} sm={12}>
                      <div className="mb-[33px]">
                        <input
                          type="text"
                          name="address"
                          className="rounded-[5px] py-[13px] px-[20px]  w-full border-[1px] border-solid border-[#dfdfdf] overflow-hidden"
                          placeholder="Your address"
                          {...register("address", {
                            required: {
                              value: true,
                              message: "Address is required",
                            },
                          })}
                        />
                        <div className="absolute text-[tomato] text-[12px] translate-y-[-3px] translate-x-[10px]">
                          {errors.address?.message}
                        </div>
                      </div>
                    </Col>
                    <Col xl={12} lg={12} md={12} sm={12}>
                      <div className="mb-[33px]">
                        <input
                          type="text"
                          name="stream"
                          className="rounded-[5px] py-[13px] px-[20px] w-full border-[1px] border-solid border-[#dfdfdf] overflow-hidden"
                          placeholder="MBA"
                          {...register("stream", {
                            required: {
                              value: true,
                              message: "Stream is required",
                            },
                            value: "MBA",
                          })}
                        />
                        <div className="absolute text-[tomato] text-[12px] translate-y-[-3px] translate-x-[10px]">
                          {errors.stream?.message}
                        </div>
                      </div>
                    </Col>
                    <Col xl={12} lg={12} md={12} sm={12}>
                      <div className="mb-[33px]">
                        <textarea
                          type="text"
                          rows={3}
                          name="message"
                          className="rounded-[5px] py-[13px] px-[20px]  w-full border-[1px] border-solid border-[#dfdfdf] overflow-hidden"
                          placeholder="Your message"
                          {...register("message", {
                            required: {
                              value: true,
                              message: "Message is required",
                            },
                          })}
                        />
                        <div className="absolute text-[tomato] text-[12px] translate-y-[-10px] translate-x-[10px]">
                          {errors.message?.message}
                        </div>
                      </div>
                    </Col>
                  </m.div>

                  <Buttons
                    type="submit"
                    className={`btn-fill text-sm leading-none font-medium tracking-[1px] !py-[17px] px-[32px] rounded-[4px] w-full uppercase mb-[5px] ${
                      formLoading ? "loading" : ""
                    }`}
                    themeColor="#f78c1f"
                    color="#fff"
                    size="lg"
                    title="Request a call schedule"
                  />
                </form>
              </div>
            </Col>
          </Row>
        </Container>
        <ToastContainer />
      </m.section>
      {/* enquiry form section end */}
    </>
  );
}

export default Pharmacy;
