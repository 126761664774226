import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Col } from "react-bootstrap/esm";
import { useForm } from "react-hook-form";

/** css */
import "../../../Assets/scss/BhartiEdu/pages/HomeNewsletter.scss";

// data

// import { requestCallback } from "../../Services/api";

import img from "../../../Assets/img/BhartiEdu/home/about_1.webp";
import { requestCallback } from "../../Service/api";

function MainModal() {
  /** modal start */
  const [show, setShow] = useState(false);

  useEffect(()=>{
    if (!localStorage.getItem("showFalse")) {
      setShow(true);
    }
  },[])

  useEffect(() => {
    if (!localStorage.getItem("showFalse")) {
      console.log("hello")
      if(!show){
        setTimeout(() => {
          console.log("true");
            setShow(true);
          }, 30000);
        }
      }
  },[show]);

  const handleClose = () => {};
  /** modal end */

  /** form start */
  const createUser = useForm({ mode: "all" });
  const {
    register: userRegister,
    handleSubmit: userHandleSubmit,
    formState: userFormState,
  } = createUser;

  const { errors } = userFormState;

  const onCreateUser = (userData) => {
    userData["client"] = "bhartiedu.in";
    requestCallback(userData)
      .then(() => {
        alert(
          "Your request has been registered. You will receive a callback soon."
        );
        setShow(false);
        localStorage.setItem("showFalse", true);
      })
      .catch(() => {
        setShow(false);
        alert("Technical issues detected. Please try after some time.");
      });
    /** new */
    // console.log(userData);
    
  };
  /** form end */
  return (
    <>
      <div className="newsletter">
        <Modal show={show} onHide={handleClose} centered size="lg">
          <Modal.Header
            className="absolute top-0 right-0	color: #f0fff4 border-0"
            style={{ zIndex: 1,  }}
          >
            <button
              className=" font-bold py-0 px-2 rounded sm:text-black md:text-black xl:text-white"
              onClick={() => {
                setShow(false);
                // localStorage.setItem("showFalse", true);
              }}
            >
              <i className="fas fa-close fa-lg"></i>
            </button>
          </Modal.Header>
          <Modal.Body className="py-0">
            <div className="justify-center row">
              <Col
                lg={{ span: 6, order: 1 }}
                md={{ span: 12, order: 1 }}
                sm={{ span: 12, order: 1 }}
                xs={{ span: 12, order: 1 }}
                className="bg_image bg-cover cover-background md:h-[350px] md:w-[100%] flex justify-center align-center "
                // style={{
                //   backgroundImage: `url()`,
                // }}
              >
                <img src={img} alt="" />
              </Col>
              <Col
                className="p-12 lg:p-10 xs:p-[2rem] main_modal_form"
                lg={{ span: 6, order: 2 }}
                md={{ span: 12, order: 2 }}
                sm={{ span: 12, order: 2 }}
                xs={{ span: 12, order: 2 }}
              >
                <span
                  className="text-xlg font-medium text-[#fff] block mb-[10px]"
                  style={{
                    letterSpacing: "1px",
                    textTransform: "capitalize",
                  }}
                >
                  Get Personalized colleges Now!
                </span>
                <p className="mb-[30px] xs:mb-[20px]">
                  We would love to be in touch with you, fill your details for
                  Exclusive Details!
                </p>

                <div className="relative subscribe-style-05 news_letter mb-[25px]">
                  <form
                    className="static newsletter_form "
                    autoComplete="false"
                    onSubmit={userHandleSubmit(onCreateUser)}
                  >
                    <div className="form_group ">
                      <input
                        type="text"
                        className="border-[1px]  medium-input border-solid border-[#dfdfdf]"
                        placeholder="Enter your name"
                        {...userRegister("name", {
                          required: {
                            value: true,
                            message: "Name is required",
                          },
                        })}
                      />
                      <div className="error_message">
                        {errors.name?.message}
                      </div>
                    </div>
                    <div className="form_group ">
                      <input
                        type="text"
                        className="border-[1px]  medium-input border-solid border-[#dfdfdf]"
                        placeholder="Enter your address"
                        {...userRegister("address", {
                          required: {
                            value: true,
                            message: "Address is required",
                          },
                        })}
                      />
                      <div className="error_message">
                        {errors.address?.message}
                      </div>
                    </div>

                    <div className="form_group">
                      <input
                        type="number"
                        className="border-[1px] medium-input border-solid border-[#dfdfdf]"
                        placeholder="Enter phone number"
                        {...userRegister("phone", {
                          required: {
                            value: true,
                            message: "Phone Number is required",
                          },
                          pattern: {
                            value: /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/,
                            message: "Invalid Number",
                          },
                        })}
                      />
                      <div className="error_message ">
                        {errors.phone?.message}
                      </div>
                    </div>

                    <button
                      type="submit"
                      className="text-white w-[100%] !text-[12px]"
                      style={{ fontWeight: "500", letterSpacing: "1px" }}
                    >
                      Discover Unbeatable Colleges
                    </button>
                  </form>
                </div>
              </Col>
            </div>
          </Modal.Body>
          {/* <Modal.Footer>
            <button
              variant="secondary"
              onClick={() => {
                setShow(false);
                localStorage.setItem("showStatus", false);
              }}
            >
              Close
            </button>
            <button variant="primary" onClick={handleClose}>
              Save Changes
            </button>
          </Modal.Footer> */}
        </Modal>
      </div>
    </>
  );
}

export default MainModal;
