// const HeaderDataAlpha = [
//   {
//     title: "Home",
//     link: "/",
//   },
//   {
//     title: "About",
//     link: "/about-us",
//   },
//   {
//     title: "Courses",

//     // dropdown: [
//     //   {
//     //     title: "MBBS",
//     //     link: "/course/mbbs",
//     //   },
//     //   {
//     //     title: "Engineering",
//     //     link: "/course/engineering",
//     //   },
//     //   {
//     //     title: "Hotel Management",
//     //     link: "/course/hotel-management",
//     //   },
//     //   {
//     //     title: "Fashion Designing",
//     //     link: "/course/fashion-designing",
//     //   },
//     //   {
//     //     title: "MASS COM & All Type Of GR",
//     //     link: "/course/mass-com-&-all-type-gr",
//     //   },
//     //   {
//     //     title: "BBA/BCA/B.COM/BSC",
//     //     link: "/course/bba-bca-bcom-bsc",
//     //   },
//     //   {
//     //     title: "LLB/LLM",
//     //     link: "/course/llb-llm",
//     //   },
//     //   {
//     //     title: "MBA",
//     //     link: "/course/mba",
//     //   },
//     //   {
//     //     title: "MDS",
//     //     link: "/course/mds",
//     //   },
//     //   {
//     //     title: "B.Pharma",
//     //     link: "/course/bpharma",
//     //   },
//     //   {
//     //     title: "BDS",
//     //     link: "/course/bds",
//     //   },
//     //   {
//     //     title: "MDS/S",
//     //     link: "/course/mds-s",
//     //   },
//     // ],
//   },
//   {
//     title: "Counselling",
//     link: "/counselling",
//   },
//   {
//     title: "Contact us",
//     link: "/contact-us",
//   },
// ];

// export default HeaderDataAlpha;

const HeaderDataAlpha = [
  {
    title: "Home",
    link: "/",
  },

  {
    title: "Course",

    // dropdown: [
    //   {
    //     title: "PCM",
    //     link: "/course/pcm",
    //   },
    //   {
    //     title: "PCB",
    //     link: "/course/pcb",
    //   },
    //   {
    //     title: "Commerce",
    //     link: "/course/commerce",
    //   },
    //   {
    //     title: "Arts",
    //     link: "/course/arts",
    //   },
    //   {
    //     title: "Diploma",
    //     link: "/course/diploma",
    //   },
    // ],
    megamenu: [
      {
        title: "Degree Courses",
        dropdown: [
          {
            title: "MBA",
            link: "/course/mba",
          },
          {
            title: "BBA",
            link: "/course/bba",
          },

          // {
          //   title: "BA LLB",
          //   link: "/course/ba-llb",
          // },

          {
            title: "LLB / BBA LLB",
            link: "/course/bba-llb",
          },

          {
            title: "B-Tech",
            link: "/course/b-tech",
          },
          {
            title: "Diploma",
            link: "/course/diploma",
          },
        ],
      },
      {
        title: "Medical Courses",
        dropdown: [
          {
            title: "MBBS",
            link: "/course/mbbs",
          },
          {
            title: "BAMS",
            link: "/course/bams",
          },
          {
            title: "BHMS",
            link: "/course/bhms",
          },
          {
            title: "BDS",
            link: "/course/bds",
          },
          {
            title: "B.sc Nursing",
            link: "/course/nursing",
          },
          {
            title: "B Pharma/Pharm D",
            link: "/course/pharmacy",
          },
        ],
      },
      {
        title: ".",
        dropdown: [
          {
            title: "Bio Tech/B.Sc Bio Tech",
            link: "/course/biotech",
          },
          {
            title: "B.Sc Radiology",
            link: "/course/radiology",
          },
          {
            title: "B.Sc Microbiology",
            link: "/course/microbiology",
          },
          {
            title: "Bachelor in Physio Therapy",
            link: "/course/physio-therapy",
          },
          {
            title: "B.Sc Forensic",
            link: "/course/forensic",
          },
        ],
      },
    ],
  },
  // {
  //   title: "Counselling",
  //   link: "/counselling",
  // },

  {
    title: "About",
    link: "/about-us",
  },
  {
    title: "Contact us",
    link: "/contact-us",
  },
];

export default HeaderDataAlpha;
