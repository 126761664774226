const topUniversityData = [
  {
    stateName: "Maharastra",
    title: "B Pharma / Pharm D admission in Maharastra",
    bgColor: "#8D2EAE",
    colleges: [
      {
        name: "KVV Karad - Krishna Vishwa Vidyapeeth, Karad",
      },
      {
        name: "Poona College of Pharmacy",
      },
      {
        name: "Indira College of Pharmacy, Pune",
      },
      {
        name: "Siddhant College of pharmacy",
      },
      {
        name: "Rajmata Jijau Shikshan Prasarak Mandal's College/Institute Of Pharmacy",
      },
      {
        name: "BCP Mumbai - Bombay College of Pharmacy, Mumbai",
      },
      {
        name: "CPN Nashik - MVP Samaj's College of Pharmacy, Nashik",
      },
      {
        name: "DBCDIP - Dadasaheb Balpande College of Diploma in Pharmacy, Nagpur",
      },
      {
        name: "OCP Navi Mumbai - Oriental College of Pharmacy, Navi Mumbai",
      },
      {
        name: "Modern College of Pharmacy Nigdi Pune",
      },
    ],
  },
  {
    stateName: "Uttar Prades ",
    title: "B Pharma / Pharm D admission in Uttar Pradesh",
    bgColor: "#285FB2",
    colleges: [
      {
        name: "ACP Aligarh - Aligarh College of Pharmacy, Aligarh",
      },
      {
        name: "AIPR Lucknow - Azad Institute of Pharmacy and Research, Lucknow",
      },
      {
        name: "BBDU Lucknow - Babu Banarasi Das University, Lucknow",
      },
      {
        name: "GIPS Lucknow - Goel Institute of Pharmacy and Science, Lucknow",
      },
      {
        name: "Galgotias University - Galgotias University, Greater Noida",
      },
      {
        name: "HIMS Lucknow - Hind Institute of Medical Sciences, Barabanki",
      },
      {
        name: "MIT Meerut - Meerut Institute of Technology, Meerut",
      },
      {
        name: "KIET Group of Institutions, Ghaziabad (KIET Ghaziabad)",
      },
      {
        name: "MMC Muzaffarnagar - Muzaffarnagar Medical College, Muzaffarnagar",
      },
      {
        name: "NIET Greater Noida - Noida Institute of Engineering and Technology",
      },
    ],
  },
  {
    stateName: "Madhya Pradesh",
    title: "B Pharma / Pharm D admission in Madhya Pradesh",
    bgColor: "#FE9900",
    colleges: [
      {
        name: "AIPER Indore",
      },
      {
        name: "BVM Gwalior - BVM College of Pharmacy, Gwalior",
      },
      {
        name: "CMCH Bhopal - Chirayu Medical College and Hospital, Bhainsakhedi",
      },
      {
        name: "GGITS Jabalpur",
      },
      {
        name: "Indore Institute of Pharmacy, Indore",
      },
      {
        name: "Lakshmi Narain College of Pharmacy, Bhopal",
      },
      {
        name: "MRSC Indore",
      },
      {
        name: "Oriental College of Pharmacy, Bhopal",
      },
      {
        name: "RDGMC Ujjain - RD Gardi Medical College, Ujjain",
      },
      {
        name: "A and E College of Pharmacy, Samastipur",
      },
    ],
  },
  {
    stateName: "Andhara Pradesh",
    title: "B Pharma / Pharm D admission in Andhara Pradesh",
    bgColor: "#ED0181",
    colleges: [
      {
        name: "CESCOP Kurnool - Creative Educational Society's College of Pharmacy",
      },
      {
        name: "CHIPS Guntur - Chebrolu Hanumaiah Institute of Pharmaceutical Sciences",
      },
      {
        name: "GITAM Institute of Technology, Visakhapatnam (GITAM University)",
      },
      {
        name: "KIMS Amalapuram - Konaseema Institute of Medical Sciences and Research",
      },
      {
        name: "NCP Guntur - Nirmala College of Pharmacy, Atmakur",
      },
      {
        name: "PESIMSR Kuppam - PES Institute of Medical Sciences and Research",
      },
      {
        name: "Raghavendra Institute of Pharmaceutical Education and Research",
      },
      {
        name: "SHCP Tirupati - Seven Hills College of Pharmacy, Tirupati",
      },
      {
        name: "AKRG College of Pharmacy, Nallajerla",
      },
      {
        name: "AM Reddy Memorial College of Pharmacy, Narasaraopet",
      },
    ],
  },

  {
    stateName: "Karnataka",
    title: "B Pharma / Pharm D admission in Karnataka",
    bgColor: "#369752",
    colleges: [
      {
        name: "ABMRCP Bangalore - Acharya BM Reddy College of Pharmacy, Bangalore",
      },
      {
        name: "AIMS Mandya - Adichunchanagiri Institute of Medical Sciences and Research",
      },
      {
        name: "BCC Bangalore - Bangalore City College, Bangalore",
      },
      {
        name: "Dayananda Sagar University, Bangalore",
      },
      {
        name: "GCP Bangalore - Gautham College of Pharmacy, Bangalore",
      },
      {
        name: "JJMMC Davangere - JJM Medical College, Davangere",
      },
      {
        name: "JNMC Belgaum - Jawaharlal Nehru Medical College, Belgaum",
      },
      {
        name: "KBNIMS Gulbarga - Khaja Bandanawaz Institute of Medical Sciences",
      },
      {
        name: "KIMS Bangalore - Kempegowda Institute of Medical Sciences, Bangalore",
      },
      {
        name: "KLE Bangalore - KLE College of Pharmacy, Bengaluru",
      },
    ],
  },
  {
    stateName: "Tamil Nadu",
    title: "B Pharma / Pharm D admission in Tamil Nadu",
    bgColor: "#B07926",
    colleges: [
      {
        name: "JKKN College of Pharmacy - JKK Nattraja College of Pharmacy",
      },
      {
        name: "The Ponnaiyah Ramajayam Institute of Science and Technology, Thanjavur",
      },
      {
        name: "Sathyabama University",
      },
      {
        name: "Vel's Institute of Science Technology",
      },
      {
        name: "Aadhi Bhagawan College of Pharmacy, Tiruvannamalai",
      },
      {
        name: "Adhiparasakthi College of Pharmacy, Kancheepuram",
      },
      {
        name: "Annai Veilankanni's Pharmacy College, Chennai",
      },
      {
        name: "Arunai College of Pharmacy, Tiruvannamalai",
      },
      {
        name: "Bharath Institute of Higher Education and Research, Chennai",
      },
      {
        name: "CL Baid Metha College of Pharmacy, Chennai",
      },
    ],
  },

  {
    stateName: "Rajasthan",
    title: "B Pharma / Pharm D admission in Rajasthan",
    bgColor: "#DB2B2D",
    colleges: [
      {
        name: "BITS Pilani - Birla Institute of Technology and Science, Pilani",
      },
      {
        name: "Jaipur National University - Jaipur National University, Jaipur",
      },
      {
        name: "Apex University, Jaipur",
      },
      {
        name: "Arya College of Pharmacy, Jaipur",
      },
      {
        name: "Bhagwant University, Ajmer",
      },
      {
        name: "Bhupal Nobel's College of Pharmacy, Udaipur",
      },
      {
        name: "Career Point University, Kota",
      },
      {
        name: "Dr KN Modi University, Tonk",
      },
      {
        name: "Geetanjali Institute of Pharmacy, Udaipur",
      },
      {
        name: "GD Memorial College of Pharmacy, Jodhpur",
      },
    ],
  },
  {
    stateName: "Chhattisgarh",
    title: "B Pharma / Pharm D admission in Chhattisgarh",
    bgColor: "#5000FA",
    colleges: [
      {
        name: "SSGI Bhilai - Shri Shankaracharya Group of Institutions, Bhilai",
      },
      {
        name: "Apollo College of Pharmacy, Durg",
      },
      {
        name: "Bharti College of Pharmacy, Durg",
      },
      {
        name: "Chhatrapati Shivaji Institute of Pharmacy, Durg",
      },
      {
        name: "Columbia Institute of Pharmacy, Raipur",
      },
      {
        name: "Danteswari College of Pharmacy, Jagdalpur",
      },
      {
        name: "Dr CV Raman Institute of Pharmacy, Bilaspur",
      },
      {
        name: "Faculty of Pharmacy, Kalinga University, Raipur",
      },
      {
        name: "JK College of Pharmacy, Bilaspur",
      },
      {
        name: "Kalinga University, Raipur",
      },
    ],
  },
];
export default topUniversityData;
