const FooterData = [
  {
    title: "company",
    submenu: [
      { title: "Home", link: "/" },
      {
        title: "About company",
        link: "/about-us",
      },
      // {
      //   title: "Counselling",
      //   link: "/counselling",
      // },

      {
        title: "Contact us",
        link: "/contact-us",
      },
      {
        title: "Privacy Policy",
        link: "/privacy-policy",
      },
    ],
  },

  {
    title: "Top Courses",
    submenu: [
      {
        title: "MBBS",
        link: "/course/mbbs",
      },
      {
        title: "Engineering",
        link: "/course/engineering",
      },
      {
        title: "MBA",
        link: "/course/mba",
      },
      {
        title: "B Pharma/Pharm D",
        link: "/course/pharmacy",
      },
    ],
  },
  {
    title: "Resources",
    submenu: [
      {
        title: "Theme guide",
        link: "/page/faq-s",
      },
      {
        title: "Support desk",
        link: "/page/faq-s",
      },
      {
        title: "What we offer",
        link: "/page/what-we-offer",
      },
      {
        title: "Company history",
        link: "/page/our-story",
      },
    ],
  },
  {
    title: "Categories",
    submenu: [
      {
        title: "For men",
        link: "/shop/shop-wide",
      },
      {
        title: "For woman",
        link: "/shop/shop-wide",
      },
      {
        title: "Accessories",
        link: "/shop/shop-wide",
      },
      {
        title: "Collections",
        link: "/shop/shop-wide",
      },
    ],
  },
  {
    title: "Adavantages",
    submenu: [
      {
        title: "Free delivery",
        link: "/page/faq-s",
      },
      {
        title: "100 days refund",
        link: "/page/faq-s",
      },
      {
        title: "Multiple payments",
        link: "/page/faq-s",
      },
      {
        title: "Sustainable",
        link: "/page/faq-s",
      },
    ],
  },
];

export default FooterData;
