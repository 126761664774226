import React from "react";
import FooterStyle03 from "./FooterStyle03";

const FooterMain = () => {
  return (
    <div>
      {/* Footer Start */}
      <FooterStyle03
        theme="dark"
        className="text-[#828282] bg-[#0d2847] consulting-footer"
      />
      {/* Footer End */}
    </div>
  );
};

export default FooterMain;
