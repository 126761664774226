import React, { Suspense, useEffect, useState, lazy } from "react";

// Libraries
import { Routes, Route, useLocation } from "react-router-dom";
import retina from "retinajs";
import { AnimatePresence } from "framer-motion";

// Context
import GlobalContext from "./BhartiEdu/Context/Context";

// Components
import ScrollToTopButton from "./BhartiEdu/Components/ScrollToTop";

import HeaderAlpha from "./BhartiEdu/Components/HeaderMain";
import FooterAlpha from "./BhartiEdu/Components/FooterMain";
import NotFoundPage from "./BhartiEdu/Pages/404";
import MainModal from "./BhartiEdu/Pages/Home/MainModal";
import Pharmacy from "./BhartiEdu/Pages/Courses/Pharmacy";
// import Pharmacy from "./BhartiEdu/Pages/Courses/Pharmacy/Pharmacy";

// Image
import phone from "./Assets/img/BhartiEdu/logo/phone.webp";
import PrivacyPolicy from "./BhartiEdu/Pages/PrivacyPolicy/PrivacyPolicy";

const Header = React.lazy(() =>
  import("./BhartiEdu/Components/HeaderMain/Header").then((module) => ({
    default: module.Header,
  }))
);

// pages

const HomePage = lazy(() => import("./BhartiEdu/Pages/Home"));
const AboutUs = lazy(() => import("./BhartiEdu/Pages/AboutUs"));
const Projects = lazy(() => import("./BhartiEdu/Pages/Projects"));
const Contact = lazy(() => import("./BhartiEdu/Pages/ContactUs"));
// const Counselling = lazy(() => import("./BhartiEdu/Pages/Counselling"));
const Mbbs = lazy(() => import("./BhartiEdu/Pages/Courses/MBBS"));
const Btech = lazy(() => import("./BhartiEdu/Pages/Courses/Btech"));
const HotelManagement = lazy(() =>
  import("./BhartiEdu/Pages/Courses/HotelManagement")
);
const FashionDesigning = lazy(() =>
  import("./BhartiEdu/Pages/Courses/FashionDesigning")
);
const MassCom = lazy(() => import("./BhartiEdu/Pages/Courses/MassCom"));
const Bba = lazy(() => import("./BhartiEdu/Pages/Courses/Bba"));
// const LlbLlm = lazy(() => import("./BhartiEdu/Pages/Courses/LlbLlm"));
const Mba = lazy(() => import("./BhartiEdu/Pages/Courses/MBA"));
const Mds = lazy(() => import("./BhartiEdu/Pages/Courses/Mds"));
// const Bpharma = lazy(() => import("./BhartiEdu/Pages/Courses/Bpharma"));
const Bds = lazy(() => import("./BhartiEdu/Pages/Courses/Bds"));
const Mdss = lazy(() => import("./BhartiEdu/Pages/Courses/Mdss"));
// const BaLlb = lazy(() => import("./BhartiEdu/Pages/Courses/BaLlb"));
const BbaLlb = lazy(() => import("./BhartiEdu/Pages/Courses/BbaLlb"));
const Diploma = lazy(() => import("./BhartiEdu/Pages/Courses/Diploma"));
const Bams = lazy(() => import("./BhartiEdu/Pages/Courses/Bams"));
const Nursing = lazy(() => import("./BhartiEdu/Pages/Courses/Nursing"));
const Biotech = lazy(() => import("./BhartiEdu/Pages/Courses/Biotech"));
const Radiology = lazy(() => import("./BhartiEdu/Pages/Courses/Radiology"));
const Microbiology = lazy(() =>
  import("./BhartiEdu/Pages/Courses/Microbiology")
);
const PhysioTherapy = lazy(() =>
  import("./BhartiEdu/Pages/Courses/PhysioTherapy")
);
const Forensic = lazy(() => import("./BhartiEdu/Pages/Courses/Forensic"));
const Bhms = lazy(() => import("./BhartiEdu/Pages/Courses/Bhms"));
// const Pharmacy = lazy(() => import("./BhartiEdu/Pages/Courses/Pharmacy"));

// image

function App() {
  const [headerHeight, setHeaderHeight] = useState(0);
  const [footerHeight, setFooterHeight] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [customModal, setCustomModal] = useState({
    el: null,
    isOpen: false,
  });
  const location = useLocation();

  // RetinaJS
  useEffect(() => {
    window.addEventListener("load", retina(document.querySelectorAll("img")));
  }, []);

  useEffect(() => {
    setTimeout(() => {
      import("./Theme/Functions/Utilities").then((module) => {
        module.SetHeaderMenuPos();
        module.setDocumentFullHeight();
      });
    }, 1000);
  }, [location]);

  useEffect(() => {
    if (isModalOpen === true) {
      document.querySelector("body").classList.add("overflow-hidden");
    } else {
      document.querySelector("body").classList.remove("overflow-hidden");
    }
  }, [isModalOpen]);

  // Get the current location and set the window to top
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
    setFooterHeight(0);
    setCustomModal({
      ...customModal,
      el: null,
      isOpen: false,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <GlobalContext.Provider
      value={{
        headerHeight,
        setHeaderHeight,
        footerHeight,
        setFooterHeight,
        isModalOpen,
        setIsModalOpen,
        customModal,
        setCustomModal,
      }}
    >
      <div className="App" style={{ "--header-height": `${headerHeight}px` }}>
        {
          <main style={{ marginTop: headerHeight, marginBottom: footerHeight }}>
            {/* Header Start */}
            <Header topSpace={{ md: false }} type="reverse-scroll">
              <HeaderAlpha />
            </Header>
            <div className="main_contact">
              <div className="main_contact_container">
                <div className="contact_img">
                  <a href="tel: +91 6572442721">
                    <img src={phone} width={40} height={40} alt="phone icon" />
                    <span>+91 6572442721</span>
                  </a>
                </div>
                {/* <div className="contact_number">
                  <span>+</span>
                  <span>9</span>
                  <span>1</span>
                  <span>6</span>
                  <span>5</span>
                  <span>7</span>
                  <span>2</span>
                  <span>4</span>
                  <span>4</span>
                  <span>2</span>
                  <span>7</span>
                  <span>2</span>
                  <span>1</span>
                </div> */}
              </div>
            </div>
            {/* Header End */}
            <ScrollToTopButton />
            <AnimatePresence exitBeforeEnter>
              <Suspense fallback={<div>Loading...</div>}>
                <Routes>
                  <Route
                    path="/"
                    element={<HomePage style={{ "--base-color": "#27ae60" }} />}
                  />
                  <Route path="about-us" element={<AboutUs />} />
                  <Route path="contact-us" element={<Contact />} />
                  <Route path="projects" element={<Projects />} />
                  <Route path="privacy-policy" element={<PrivacyPolicy />} />
                  <Route path="services"></Route>
                  {/*<Route path="counselling" element={<Counselling />} /> */}
                  <Route path="course/mbbs" element={<Mbbs />} />
                  <Route path="course/b-tech" element={<Btech />} />
                  <Route
                    path="course/hotel-management"
                    element={<HotelManagement />}
                  />
                  <Route
                    path="course/fashion-designing"
                    element={<FashionDesigning />}
                  />
                  <Route
                    path="course/mass-com-&-all-type-gr"
                    element={<MassCom />}
                  />
                  <Route path="course/bba" element={<Bba />} />
                  {/* <Route path="course/llb-llm" element={<LlbLlm />} /> */}
                  <Route path="course/mba" element={<Mba />} />
                  <Route path="course/mds" element={<Mds />} />
                  {/* <Route path="course/bpharma" element={<Bpharma />} /> */}
                  <Route path="course/bds" element={<Bds />} />
                  <Route path="course/mds-s" element={<Mdss />} />
                  {/* <Route path="course/ba-llb" element={<BaLlb />} /> */}
                  <Route path="course/bba-llb" element={<BbaLlb />} />
                  <Route path="course/diploma" element={<Diploma />} />
                  <Route path="course/bams" element={<Bams />} />
                  <Route path="course/nursing" element={<Nursing />} />
                  <Route path="course/biotech" element={<Biotech />} />
                  <Route path="course/Pharmacy" element={<Pharmacy />} />
                  <Route path="course/radiology" element={<Radiology />} />
                  <Route
                    path="course/microbiology"
                    element={<Microbiology />}
                  />
                  <Route
                    path="course/physio-therapy"
                    element={<PhysioTherapy />}
                  />
                  <Route path="course/forensic" element={<Forensic />} />

                  <Route path="course/bhms" element={<Bhms />} />

                  <Route path="*" element={<NotFoundPage />} />
                </Routes>
              </Suspense>
            </AnimatePresence>
            {/* Footer Start */}

            <FooterAlpha />
            {/* Footer End */}

            <MainModal />
          </main>
        }
      </div>
    </GlobalContext.Provider>
  );
}

export default App;
