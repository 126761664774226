import React from "react";
import "../../../Assets/scss/components/_whatsappButton.scss";

const WhatsappButton = () => {
  return (
    <>
      <div className="mt-[40px] flex gap-8">
        <div className="whatsappBtn">
          <a
            className="text-[#fff]"
            href="https://wa.me/+919730858444"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fa-brands fa-whatsapp"></i>
            <span className="m-2">Enquire on Whatsapp</span>
          </a>
        </div>
        <div className="callBtn">
          <a className="text-[#fff]" href="tel: +91 6572442721">
            <i className="fa-solid fa-phone"></i>
            <span className="m-2">Call Us +91 6572442721</span>
          </a>
        </div>
      </div>
    </>
  );
};

export default WhatsappButton;
