import React, { useState } from "react";

import { Col, Navbar, Row, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import Marquee from "react-fast-marquee";

import { Topbar } from "../../Components/HeaderMain/Header";

import Header, { HeaderNav, Menu } from "../../Components/HeaderMain/Header";

// Logo
import bhartiedu from "../../../Assets/img/BhartiEdu/logo/bhartiedu.webp";
import bhartieduWhite from "../../../Assets/img/BhartiEdu/logo/bhartieduWhite.webp";

const HeaderMain = (props) => {
  const [open, setOpen] = useState(false);

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      {/* Header Start */}
      <Header
        topSpace={{ md: true }}
        type="reverse-scroll border-b border-[#ffffff1a]"
      >
        <Topbar className="header-with-topbar bg-white border-bottom pl-[35px] pr-[50px] sm:hidden lg:pr-[50px] md:pl-[15px] md:pr-[30px]">
          <Container fluid>
            <Row className="flex items-center justify-between">
              <Col sm="9">
                <Marquee className="text-md" pauseOnHover="true">
                  Ready to tackle the direct admission process&nbsp;
                  <Link
                    to="/contact-us"
                    className="text-[#ff6403] font-medium underline hover:text-[#bf8c4c]"
                  >
                    Talk To An Advisor
                  </Link>
                  <a
                    className="text-[#25D366] mx-4"
                    href="https://wa.me/+919730858444"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fa-brands fa-whatsapp"></i>
                    <span className="m-2">Enquire on Whatsapp</span>
                  </a>
                  <a className="text-[#ff6402]" href="tel: +91 6572442721">
                    <i className="feather-phone"></i>
                    <span className="m-2">Call Us +91 6572442721</span>
                  </a>
                </Marquee>
              </Col>
              <Col sm="auto" className="!pr-0">
                <div className="flex text-sm leading-[30px]">
                  <span className="flex items-center  py-[9px] px-[18px]">
                    <a
                      href="tel: +916572442721"
                      className="text-[#ff6403] hover:text-[#bf8c4c] font-medium"
                    >
                      <i className="feather-phone-call mr-[10px] text-md "></i>
                      +91 6572442721
                    </a>
                  </span>
                  {/* <span className="flex items-center border-l py-[9px] pl-[18px]">
                    <i className="feather-map-pin mr-[10px] text-md text-darkgray"></i>{" "}
                    5th floor 4/2 Sulata Mala Complex, 66 Pennar road, Sakchi
                    Jamshedpur, Jharkhand, India 831001
                  </span> */}
                </div>
              </Col>
            </Row>
          </Container>
        </Topbar>
        <HeaderNav
          fluid="fluid"
          theme="dark"
          expand="lg"
          className="py-[0px] px-[35px] lg:pr-[35px] md:px-0 md:py-[8px] "
        >
          <Col className="col-6 col-lg-2 me-auto ps-lg-0 ps-0 ps-md-3">
            <Link
              aria-label="header home link"
              className="flex items-center"
              to="/"
            >
              <Navbar.Brand className="inline-block m-0">
                <img
                  className="default-logo"
                  width="80"
                  height="80"
                  loading="lazy"
                  src={bhartieduWhite}
                  data-rjs={bhartieduWhite}
                  alt="logo"
                />
                <img
                  className="alt-logo"
                  width="111"
                  height="36"
                  loading="lazy"
                  src={bhartiedu}
                  data-rjs={bhartiedu}
                  alt="logo"
                />
                <img
                  className="mobile-logo"
                  width="111"
                  height="36"
                  loading="lazy"
                  src={bhartiedu}
                  data-rjs={bhartiedu}
                  alt="logo"
                />
              </Navbar.Brand>
            </Link>
          </Col>
          <Navbar.Toggle
            className={`order-last md:ml-[17px] md:px-[15px] ${
              !open ? "collapsed" : ""
            }`}
            onClick={handleToggle}
          >
            <span className="navbar-toggler-line"></span>
            <span className="navbar-toggler-line"></span>
            <span className="navbar-toggler-line"></span>
            <span className="navbar-toggler-line"></span>
          </Navbar.Toggle>
          <Navbar.Collapse
            className="col-auto justify-center menu-order finance-header-menu px-lg-0 col-lg-8"
            in={open}
          >
            <Menu {...props} handleClose={handleClose} />
          </Navbar.Collapse>
        </HeaderNav>
      </Header>
      {/* Header End */}
    </>
  );
};

export default HeaderMain;
